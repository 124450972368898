import React from 'react'
import './adm_inq.css'

const Admission_inquery = () => {
  return (
    <div className='admission-query'>
      <div className="box-nav"></div>

      <div className="contact-tk">
        <div className="left-contact"></div>
        <div className="right-contact">
          <form >
            <input type="text" placeholder='Student Name' />
            <label htmlFor="birthdate">Birth Date :
              <input type="date" name="birthdate" id="birthdate" />
            </label>
            <div className="gender">
              <p>Gender :</p>
              <div className="gens">
                <div>
                  <label htmlFor="male">Male </label>
                  <input type="radio" name="gender" id="male" />
                </div>
                <div>
                  <label htmlFor="female">Female </label>
                  <input type="radio" name="gender" id="female" />
                </div>
              </div>
            </div>

            <input type="text" placeholder='School Currently Studying' name="" id="" />
            <input type="numberic" placeholder='Mobile No.' name="" id="" />
            <input type="submit" value="Submit" />
          </form>
        </div>
      </div>
    </div>
  )
}

export default Admission_inquery