import React from 'react'
import './contact.css'

const Contact = () => {
    return (
        <div className='contact'>
            <div className="box-nav"></div>

           
        </div>
    )
}

export default Contact