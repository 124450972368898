import React, { useEffect, useState } from 'react';
import './navbar.css';
import { Link } from 'react-router-dom';
import logo from '../img/unnamed.png';
import dropdownimg from '../img/down-arrow.png';
import navbaricon from '../img/navigation-bar.png';
import closeImg from '../img/close.png';

const NavBar = () => {
    

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 880);
    const [menuOpen, setMenuOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false)
    const [color, setColor] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 880);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            const isScrolled = window.scrollY > 20;
            setIsScrolled(isScrolled);
            setColor(isScrolled);  // Set color to true if scrolled, false if at the top
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    return (
        <>
            {isMobile ? (
            

            <header id="vertical" className={isScrolled ? "vertical" : '' }>
                    <div className="lt-portrait">
                        <Link to={'/'}>
                            <img src={logo} height={70} alt="logo" />
                        </Link>
                    </div>
                    <div className="rt-portrait">
                        <div className="nav-icon" onClick={toggleMenu}>
                            {menuOpen ? (
                                <img className='close-img' src={closeImg} alt="" />
                            ) : (
                                <img src={navbaricon} width={27} height={30} alt="" />
                            )}

                        </div>
                        <div className={`droperctc ${menuOpen ? 'show' : 'hide'}`}>
                            <ul>
                                <li ><Link onClick={toggleMenu} to={'/'}>Home</Link></li>
                                <li><Link onClick={toggleMenu} to={'/about'}>About</Link></li>
                                <li><Link onClick={toggleMenu} to={'/admission'}>Admission</Link></li>
                                {/* <li><Link onClick={toggleMenu} to={'/query/admission-query'}>Admission Enquiry</Link></li> */}
                                <li><Link onClick={toggleMenu} to={'/'}>Curriculum</Link></li>
                                <li><Link onClick={toggleMenu} to={'/'}>Complaints</Link></li>
                                <li><Link onClick={toggleMenu} to={'/'}>Gallery</Link></li>
                                <li><Link onClick={toggleMenu} to={'/facility'}>Facility</Link></li>
                                <li><Link onClick={toggleMenu} to={'/contact'}>Contact Us</Link></li>
                            </ul>
                        </div>
                    </div>
                </header>
                
            
         ) : ( 
           
            
            <header id='horizontal' className={isScrolled ? "horizontal" : ""}>

                <div className="lt-hori">
                    <img src={logo} height={80} alt="" />
                </div>

                <div className="rt-hori">
                        <ul>
                            <Link to={'/'} className={color ? 'black' : 'white'}>Home</Link>
                            <Link to={'/about'} className={color ? 'black' : 'white'}>About</Link>
                            <Link to={'/facility'} className={color ? 'black' : 'white'}>Facility</Link>
                            <Link to={'/contact'} className={color ? 'black' : 'white'}>Contact</Link>
                        </ul>
                </div>

            </header>


        )}
        </>
    );
};

export default NavBar;
