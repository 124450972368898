import React, { useEffect, useState } from 'react'
// import imgMain from '../../img/img_925.jpeg'
// import imgPlayground from '../../img/playground1.jpg'
import './home.css'
import schoolnameimg from '../../img/schoolname.png'

const Home = () => {
    const [imgorh1, setImgorh1] = useState(false)

    useEffect(() => {
        if (window.innerWidth <= 880) {
            setImgorh1(true)
        }
    },[])
    return (
        <div className='home'>

            <div className="img-slider">
                {/* <img src={imgPlayground} alt="" /> */}
                <div className="img-part-box">


                    {imgorh1 ? (<><h1>St Paul's High School</h1></>) : (<> <img src={schoolnameimg} alt="" /></>)}
                </div>
            </div>

            <hr />

            <div className="admission">
                Enrollemnt
            </div>

            <section className="scnd-part">



            </section>


        </div>
    )
}

export default Home