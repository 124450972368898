import './App.css';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Components/home/Home';
import About from './Components/about/About';
import Admission_inquery from './Components/inquery/Admission_inquery';
import NavBar from './Navbar/NavBar';
import Footer from './footer/Footer';
import { useEffect } from 'react';
import Contact from './Components/contact/Contact';
import Complain from './Components/complain/Complain';

function App() {

  // const nav = useNavigate();

  useEffect(() => {
    document.getElementById('rupee').innerHTML = '\u20B9'; // ₹
  })



  return (
    <div className="App">


      <BrowserRouter>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/admission' element={<Admission_inquery />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/complain' element={<Complain />} />
          {/* <Route path='/' element={<Home />} /> */}



        </Routes>
        <div onClick={() =>{
          window.location = 'https://student.schoolcanvas.com/'
        }} className="box-rel">
          <h1>Fees Payment</h1>
          <p id="rupee"></p>
        </div>
        <Footer />

      </BrowserRouter>


    </div>
  );
}

export default App;


// breakpoint header 880px and 12vh heihgt of header