import React from 'react'
import './complain.css'

const Complain = () => {
  return (
    <div className='complain'>
        <div className="box-nav"></div>
        <div className="complain-form">
            <div className="head-comp">
                <h1>Complain</h1>
            </div>
            <form >
                <input type="text" placeholder='Full Name' />
                <input type="numberic" placeholder='Mobile No.' />
                <input type="email" placeholder='Email' />
                <input type="text" placeholder='Address' />
                <textarea name="" id="">Message</textarea>
                <input type="submit" value="Submit" />
            </form>
        </div>
    </div>
  )
}

export default Complain