import React from 'react'
import './footer.css'
import { Link } from 'react-router-dom'
import instacol from '../img/icons8-instagram-96.png'
import instales from '../img/icons8-instagram-100 (1).png'
import youtubecol from '../img/icons8-youtube-96.png'
import youtubeles from '../img/icons8-youtube-100.png'
import facebookcol from '../img/icons8-facebook-96.png'
import facebookles from '../img/icons8-facebook-100.png'
import linkedincol from '../img/icons8-linkedin-96.png'
import linkedinles from '../img/icons8-linkedin-100.png'

const Footer = () => {
    return (

        <footer>

            <div className="uper-footer">
                <div className="prin-msg">
                    <h1>St Pauls High School</h1>
                    <p className='ptag tag1'>Vijay Vihar, Gaushpur, Izra, <br />
                        Hajipur, Bihar - 844102.</p>
                    <p className='ptag tag2'><span>Telephone :</span> 8877599995 / 9006695211 / 8877777007 </p>

                    <p className='ptag tag3'><span>E-mail :</span> spshajipur@gmail.com | admin@stpaulshajipur.com</p>
                    <p className='ptag tag4'><span>Website :</span> www.stpaulshajipur.com</p>
                    <div className="social-media">
                        <div className="facebook media">
                            <img src={facebookles} alt="" />
                        </div>
                        <div className="youtube media">
                            <img src={youtubeles} alt="" />
                        </div>
                        <div className="linkedin media">
                            <img src={linkedinles} alt="" />
                        </div>
                        <div className="instagram media">
                            <img src={instales} alt="" />
                        </div>
                    </div>
                </div>

                <div className="ohtw">
                    <div className="one-ohtw teww">
                        <Link to={'/'}>Home</Link>
                        <Link to={'/about'}>About</Link>
                        <Link to={'/admission'}>Admission</Link>
                        <Link to={'/complain'}>Complaints</Link>


                    </div>
                    <div className="two-ohtw teww">
                        <Link to={'/'}>Sports</Link>
                        <Link to={'/'}>Gallery</Link>
                        <Link to={'/'}>Facility</Link>
                        <Link to={'/'}>Resources</Link>
                    </div>
                    <div className="three-ohtw teww">
                        <Link to={'/'}>Downloads</Link>
                        <Link to={'/'}>Aluminis'</Link>
                        <Link to={'/'}>Disclosure</Link>
                        <Link to={'/'}>Contact Us</Link>
                    </div>
                    <div className="three-ohtw teww">
                        <Link to={'/'}>Fee Pay</Link>
                        <Link to={'/'}>Disclosure</Link>
                        <Link to={'/'}>Contact Us</Link>
                        <Link to={'/'}>Transfer Certificate</Link>
                    </div>
                </div>
            </div>

            <div className="copyright">
                <p>© 2024 St.Paul's High School, All right reserved. Design By <a href="https://knoxo.in">Knoxo</a></p>
            </div>

        </footer>

    )
}

export default Footer